<template>
  <div class="home">

  <!-- <b-navbar class="nav justify-content-center"  type="dark" variant="dark"> -->
    <b-navbar class="nav justify-content-center" >
    <b-navbar-nav>
      <b-nav-item @click="Show('#amersfoort')">Amersfoort</b-nav-item>
      <b-nav-item @click="Show('#omgeving')">Omgeving</b-nav-item>
    </b-navbar-nav>
  </b-navbar>

  <div    v-for="item in thepois.amersfoort" v-bind:key="item.name" >{{item.name}}

  </div>

<div id="vuecontent">

      <div v-if="currentItemSelected" class="popup">
        <img class="itemimg"  v-bind:src="'/images/' + currentItem.image" />
        <div class="verhaal">{{currentItem.verhaal}}</div>

        <div class="closeButtonContainer">            
              <div class="closeButton button ui" @click="closePopup()">Ok</div>
              <!-- <div class="closeButton" @click="closePopup()">Ok</div> -->
        </div>
  
      </div>

</div>
   
<a-scene embedded vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: true" >
  <a-assets>
    <img id="kerk1" src="kerk-new.jpg" rot="-90">
    <img id="amersfoort" src="level2-new-5000x2500.jpg" rot="270">
    <img id="omgeving" src="level3-5000x2500.jpg" rot="270">
  </a-assets>
  
    <!-- v-if="currentIndex == index" -->

  <a-image @click="updateText(item, $event)" 
    v-for="(item, index) in thepois[currentLevel]" 
    v-bind:key="item.name" 
    v-if="showall || currentIndex == index"
    width="1" 
    height="1" 
    src="poi.png" 
    v-bind:position="item.position" 
    v-bind:color="item.color" 
    look-at="[camera]">
    <a-text v-bind:value="item.name" position="0 0.7 0"  align="center" ></a-text>

  </a-image>

  <a-sky id="sky" v-bind:src="currentSky" v-bind:rotation="computed_rotation" ></a-sky>

  <a-entity cursor="rayOrigin:mouse"></a-entity>

  <a-camera id="cam" fov="95" reverse-mouse-drag="true" camera wasd-controls-enabled="false">
        <a-image v-if="showall == false" id="pointer" width="1" height="1" src="poi.png" v-bind:position="computed_pos" color="#444" ></a-image>
    </a-camera>    
</a-scene>
    
    </div>


</template>

<script>

// @ is an alias to /src
import pois from '@/assets/pois.json'

export default {
  name: 'Home',
  data: function () {
    return {
      showall:true,
      currentLevel:"#amersfoort",
      currentItemSelected: false,
      currentItem: null,
      thepois: pois,
      currentSky : "#amersfoort",
      currentRotation: 270,
      position:"-10 1 -2",
      xpos:0,
      ypos:0,
      zpos:-2,
      currentIndex:0
    }
  },
computed: {
    // a computed getter
    computed_rotation: function () {      
      return `0 ${this.currentRotation} 0`;
    },
    computed_rotation: function () {      
      return `0 ${this.currentRotation} 0`;
    },
    computed_pos: function () {      
      return `${this.xpos} ${this.ypos} ${this.zpos}`;
    }
  },
  methods: {
    updateText: function(item, event){
      //alert(item.verhaal);
      this.currentItem = item;
      this.currentItemSelected = true;
      //console.log(event);
     // console.log(event.clientY);
    },
    closePopup: function(){
      this.currentItemSelected = false;
    },  
    Show: function(id){
      this.currentIndex = 0;
      this.currentLevel = id;
      this.currentSky = id;
      this.currentRotation = 270;        
      var rot = document.querySelector(id).attributes["rot"].value;
      this.currentRotation = rot;
    },
    OnKeyDown:function(e){
      if(e.key == "p"){      
        // var camera = document.querySelector('[camera]').object3D;      
        // var direction = new THREE.Vector3();
        // var dir = camera.getWorldDirection(direction);
        // dir.multiplyScalar(1);

        var worldPosition = new THREE.Vector3();
        pointer.object3D.getWorldPosition(worldPosition);
        this.thepois[this.currentLevel][this.currentIndex].pos1 = `${worldPosition.x} ${worldPosition.y} ${worldPosition.z}`;
        console.log(worldPosition);

        // this.xpos+=dir.x;
        // this.ypos+=dir.y;
        // this.zpos+=dir.z;     

      }
      else if(e.key == "ArrowLeft"){
        this.thepois[this.currentLevel][this.currentIndex].color = "#327fa8";
        this.currentIndex--;
        if(this.currentIndex < 0){
          this.currentIndex = this.thepois.length-1;
        }
        this.thepois[this.currentIndex].color = "#f00";
      }
      else if(e.key == "ArrowRight"){        
        this.thepois[this.currentLevel][this.currentIndex].color = "#327fa8";
        this.currentIndex++;
        if(this.currentIndex == this.thepois.length){
          this.currentIndex = 0;
        }
        this.thepois[this.currentLevel][this.currentIndex].color = "#f00";
      }
      else if(e.key == "ArrowUp"){
        this.zpos-=1;        
      }
      else if(e.key == "ArrowDown"){
        this.zpos+=1;        
      }
      else if(e.key == "s"){
        localStorage.setItem("thepois", JSON.stringify(this.thepois));
      }

    }
  },
  created: function(){ 

    window.addEventListener('keydown', this.OnKeyDown);
    
    if(localStorage.thepois != null){
      var storedpois = JSON.parse(localStorage.thepois);
      this.thepois = storedpois;
    }

  },  
  components: 
  {
  }
}
</script>

<style>
b-navbar-nav {
  text-align: center;
}

a-scene {
  top: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

/* .home{
  height: 100%;
} */

/* .header{
  background-color: rgb(58, 58, 58);
  top: 0px;
    width: 100%;
    height: 40px;
} */

.itemimg {  
  max-height: 260px; 
  max-width: 380px; 
  margin-left: auto;
  margin-right: auto;
  min-width: 380px;
}

.popup {
    margin: auto;
    margin-top: 40px;
    background-color: rgb(255 255 255);    
    width: 400px;
    height: 700px;
    border-radius: 4px;
    padding: 10px;
    text-align: left;    
    font-size: 23px;
}

.verhaal{
  margin-top: 10px;
}

#btn1 {
  left: 0px;
}
#btn2 {
  left: 70px;
}
#btn3 {
  left: 140px;
}
#btn4 {
  left: 210px;
}

.nav-link{
  background-color: #fff;
  margin: 10px;
  border-radius: 4px;
}

.closeButtonContainer {
  width: 100%;
  background: #3f51b5;
  position: absolute;
  top: 740px;
}

.closeButton {
  width: 380px;
  text-align: center;    
  text-align: 50px;
  line-height: 50px;
}

.button {
  background-color: #3f51b5;
  border-radius: 5px;
  width: 100 px;
  padding: 5px;
  cursor: pointer;
}

.ui {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 20px;
}

#orientation-data {
  left: 0px;
  top: 60px;
  /* display: none; */
}
</style>
  